$cdn: 'https://cdn.millions.co';
@import "styles2/scss/_base";

.root {
  padding-top: 10px;
  width: 100%;
  z-index: 2;
}

.buttonsWrapper {
  width: 100%;
  color: $black;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: $breakpoint_l) {
    gap: 12px;
  }

  @media screen and (min-width: $breakpoint_l) {
    padding-bottom: 20px;
    gap: 15px;
  }

  .actionButtonWrapper {
    flex-grow: 1;
    width: 100%;

    @media screen and (max-width: $breakpoint_l) {
      margin-bottom: 10px;
    }

    .actionButton {
      width: 100%;
      height: 36px;
      padding: 2px;
      font-size: 14px; //per design
      letter-spacing: 0 !important; //per design
      border: 1px solid $harvest-gold;

      @media screen and (min-width: $breakpoint_l) {
        max-width: 210px;
        height: 48px;
        font-size: 18px !important; //per design

        // override of default values to reflect button size in the design
        padding: 14px 30px;
      }

      @include text-sm-medium-uppercase;
    }

    .textColorGold {
      color: $harvest-gold;
    }

    .actionButtonGrayBorder {
      // override of the default button border to reflect the design (this one button has gray border)
      border: 1px solid $light-gray;
    }

    .hubSpotButton {
      padding: 2px;

      @media screen and (min-width: $breakpoint_l) {
        padding: 8px 0px;
      }
    }
  }
}

.buttonIcon {
  margin-right: 5px;
  height: 30px;
  width: 30px;
}
