$cdn: 'https://cdn.millions.co';
@import "styles2/scss/base";

.root {
  margin: 0;
  padding: 4px 0 $mobile_section_vertical_gutter;
  position: relative;
  overflow: hidden;
  max-width: 100vw;

  @media screen and (min-width: $breakpoint_ml) {
    padding: 16px 0 $mobile_section_vertical_gutter;
  }

  @media screen and (min-width: $breakpoint_l) {
    padding: $desktop_section_vertical_gutter 0;
  }

  .backgroundTitle {
    display: none;

    @media screen and (min-width: $breakpoint_l) {
      display: block;
    }
  }
}

.body {
  position: relative;
  display: block;
}
