$cdn: 'https://cdn.millions.co';
@import "styles2/scss/base";

.root {
  display: flex;
  flex-direction: row;
  background-color: $white;
  border: 2px solid $alto;

  margin: 26px 0px;
  padding: 23px 12px;

  @media screen and (min-width: $breakpoint_l) {
    margin: 56px 0;
    padding: 45px;
  }

  .contentWrapper {
    .contentHeading {
      color: $black;
    }

    .description {
      color: $dim-grey;
    }
  }
}

.homeBackground {
  background-color: $black;
  border: none;

  .contentWrapper {
    .contentHeading {
      color: $white;
    }

    .description {
      color: $alto;
    }
  }
}

.image {
  margin: 0 auto;
  border-radius: 15px;
  width: 135px;
  height: 135px;
  object-fit: cover;

  @media screen and (min-width: $breakpoint_l) {
    width: 415px;
    height: 415px;
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  padding: 0 10px;

  @media screen and (min-width: $breakpoint_l) {
    padding: 20px;
  }

  .contentHeading {
    width: 100%;
    @include text-3xl;

    text-transform: none;

    @media screen and (min-width: $breakpoint_l) {
      @include text-7xl;

      text-transform: none;
    }
  }

  .description {
    width: 100%;
    @include text-xs-regular;

    @media screen and (min-width: $breakpoint_l) {
      @include text-4xl;

      text-transform: none;
      font-weight: 400;
    }
  }
}

.buttonWrapper {
  width: 100%;

  .button {
    // override of default values to reflect button size in the design
    padding: 8px 10px;

    @media screen and (min-width: $breakpoint_l) {
      font-size: 18px;
      padding: 16px 50px;
    }

    .buttonIcon {
      margin-right: 5px;
      height: 30px;
      width: 30px;
    }
  }
}
