$cdn: 'https://cdn.millions.co';
@import "styles2/scss/_base";

.root {
  padding: 0 16px;
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(1, [col-start] 1fr);

  @media screen and (min-width: $breakpoint_l) {
    grid-template-columns: repeat(2, [col-start] 1fr);
  }

  @media screen and (min-width: $breakpoint_l) {
    padding: 0;
  }
}

.title {
  color: $harvest-gold;
  margin-bottom: 24px;
  text-transform: uppercase;
  padding-top: 20px;

  @include text-4xl;

  @media screen and (min-width: $breakpoint_l) {
    margin-bottom: 32px;

    @include text-6xl;
  }

  @media screen and (min-width: $breakpoint_xl) {
    white-space: nowrap;

    @include text-7xl;
  }
}

.image {
  border: $border-light-grey;
  background-color: $white;
  margin: 0;
  width: 100%;

  @media screen and (min-width: $breakpoint_xl) {
    width: 492px;
    height: 492px;
  }
}

.imageAndTitleWrapper {
  @media screen and (min-width: $breakpoint_ml) {
    margin: 0;
  }
}

.adjustedPosition {
  @media screen and (min-width: $breakpoint_l) {
    padding-left: 70px;
  }
}

.contentTitle {
  text-transform: uppercase;

  @include text-4xl;

  @media screen and (min-width: $breakpoint_l) {
    margin-bottom: 15px;

    @include text-6xl;
  }

  @media screen and (min-width: $breakpoint_xl) {
    @include text-7xl;
  }
}

.buttonsWrapper {
  margin-top: 50px;
  display: grid;
  gap: 20px;

  grid-template-columns: repeat(1, 1fr);

  @media screen and (min-width: $breakpoint_xxl) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.contentWrapper {
  margin-top: 48px;
  background-color: black;

  @media screen and (min-width: $breakpoint_ml) {
    padding-left: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media screen and (min-width: $breakpoint_xl) {
    padding-left: 48px;
  }
}

.memorabiliaTopSection {
  background-color: $black;
}
