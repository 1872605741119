$cdn: 'https://cdn.millions.co';
@import "styles2/scss/base";

.root {
  margin: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  background: $white;
  outline: none;
  width: 168px;
  height: 330px;

  @media screen and (min-width: $breakpoint_l) {
    width: 300px;
    height: 442px;
  }
}

.imageWrapper {
  aspect-ratio: 1;
  overflow: hidden;
  background-color: $concrete;
  margin-bottom: 8px;
  border: $border-light-grey;
  flex-shrink: 0;
  height: auto;

  @media screen and (min-width: $breakpoint_l) {
    margin-bottom: 12px;
  }
}

.infoWrapper {
  flex-grow: 1;
  max-height: 80px;

  @media screen and (min-width: $breakpoint_l) {
    height: 168px;
  }
}

.streamerName {
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  text-align: left;
  color: $dove-gray;
  margin-bottom: 4px;

  @include inline_overflow_ellipsis;
  @include text-xs-regular-underline;

  @media screen and (min-width: $breakpoint_l) {
    @include text-sm-regular-underline;
  }
}

.statusBadge {
  position: absolute;
  top: 1px;
  left: 1px;
  padding: 8px 12px;
  @include text-xs-medium-uppercase;

  &.repeatStream {
    background-color: $harvest-gold;
    color: $black;
    @include text-base-medium;
    line-height: 14px;
  }

  &.liveNow {
    background: $maximum-red;
  }

  &.upcoming {
    background: $salem;
    color: $white;
  }

  &.ended {
    background: $black;
    color: $white;
  }
}

.streamCardTitle {
  flex-grow: 1;
  height: 60%;
  color: $black;

  @include text-sm-medium;
  @include line_clamp(2);
  height: 36px;

  @media screen and (min-width: $breakpoint_l) {
    @include text-lg-medium;
    height: 44px;
  }
}

.watchButton {
  width: 100%;
}

.daysDifference {
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  text-align: left;
  color: $dove-gray;
  margin: 4px 0;

  @include inline_overflow_ellipsis;
  @include text-sm-regular;
}
