$cdn: 'https://cdn.millions.co';
@import "styles2/scss/base";

.tabWrapper {
  display: inline-block;
  .tab {
    color: $dim-grey;
    background-color: $white;
    border: 1px solid $dim-grey;
    border-radius: 6px;
    cursor: pointer;
    padding: 6px 10px;
    margin-right: 10px;
    margin-bottom: 10px;

    @include text-xs-medium-uppercase;

    @media screen and (min-width: $breakpoint_l) {
      border: 2px solid $dim-grey;
      border-radius: 10px;
      padding: 12px 20px;
      margin-bottom: 20px;
      margin-right: 20px;

      @include text-xl;

      font-weight: 500;
    }
  }
  span {
    letter-spacing: 0;
  }

  .highlightedTab {
    color: $black;
    background-color: $harvest-gold;
    border-color: $harvest-gold;
  }
}
