$cdn: 'https://cdn.millions.co';
@import "styles2/scss/_base";

.root {
  position: relative;
  padding: $mobile_section_vertical_gutter 0;
  overflow: hidden;
  max-width: 100vw;
  border-top: 1px solid $light-gray;

  @media screen and (min-width: $breakpoint_l) {
    padding: $desktop_section_vertical_gutter 0 0;
    padding-left: 120px;
  }
}

.resolutionWrapper {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin: 0 auto;

  @media screen and (min-width: $breakpoint_l) {
    grid-template-columns: repeat(2, calc(50% - 24px));
    gap: 48px;
  }
}

.mainBlock {
  padding-bottom: 0;
  z-index: 1;

  @media screen and (min-width: $breakpoint_l) {
    padding-bottom: 64px;
  }
}

.mobileAmaBlock {
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
  padding-bottom: 32px;
  z-index: 1;

  &::before {
    position: absolute;
    content: "";
    top: -98px;
    left: -15px;
    right: -15px;
    background: $harvest-gold;
    clip-path: polygon(100% 0, -10% 100%, 100% 100%);
    height: calc(100% + 98px);
    z-index: -1;
    box-sizing: border-box;
  }

  @media screen and (min-width: $breakpoint_l) {
    display: none;
  }
}

.desktopAmaBlock {
  display: none;

  @media screen and (min-width: $breakpoint_l) {
    position: relative;
    display: block;
    padding-bottom: 66px;
    z-index: 1;

    &::before {
      position: absolute;
      content: "";
      top: -#{$desktop_section_vertical_gutter};
      left: 0;
      right: -#{$desktop_gutter};
      background: $harvest-gold;
      clip-path: polygon(100% 0, 14% 100%, 100% 100%);
      height: calc(100% + #{$desktop_section_vertical_gutter});
      z-index: -1;
      box-sizing: border-box;
    }
  }
}

.title {
  margin-bottom: 24px;
  color: $black;
  text-transform: uppercase;

  @include text-4xl;

  @media screen and (min-width: $breakpoint_l) {
    margin-bottom: 32px;

    @include text-6xl;
  }

  @media screen and (min-width: $breakpoint_xl) {
    white-space: nowrap;

    @include text-7xl;
  }
}

.description {
  margin-bottom: 24px;
  letter-spacing: -0.01em;
  color: $black;

  @include font_size(18px);
  @include line_height(25.2px);

  @media screen and (min-width: $breakpoint_l) {
    margin-bottom: 32px;

    @include font_size(24px);
    @include line_height(28.8px);
  }
}

.body {
  position: relative;
  width: 100%;
}

.howAmaWorks {
  margin-bottom: 24px;

  @media screen and (min-width: $breakpoint_l) {
    margin-bottom: 80px;
  }
}

.amaVideo {
  height: 460px;
  width: 260px;

  @media screen and (min-width: $breakpoint_l) {
    margin-left: auto;
    width: 384px;
    height: 684px;
  }
}

.linkButton {
  width: 100%;
}
