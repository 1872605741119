$cdn: 'https://cdn.millions.co';
@import "styles2/scss/base";

.pages {
  margin-top: 10px;
  width: 100%;
  overflow: hidden;
}

.navGroup {
  display: flex;
  margin-top: 10px;

  @media screen and (min-width: $breakpoint_l) {
    margin-top: 20px;
  }

  .navOption {
    color: $dim-grey;
    border: none;
    background-color: $white;
    cursor: pointer;
    margin-right: 40px;
    padding: 0 0 10px;

    @include text-sm-medium-uppercase;

    @media screen and (min-width: $breakpoint_l) {
      margin-right: 75px;
      padding-bottom: 22px;

      @include text-2xl;

      font-weight: 500;

      &:hover {
        color: $harvest-gold;
        background-color: $white;
        border-bottom: 4px solid $harvest-gold;
      }

      &:focus {
        background-color: $white;
      }
    }
  }

  .highlightedNavOption {
    color: $harvest-gold;
    border-bottom: 4px solid $harvest-gold;
  }
}

.navSeparator {
  position: relative;
  top: -1px;
  border-top: 1px solid $gainsboro;
  // neutralizing the padding of "main" parent class to display the line in full screen width on mobile
  left: -16px;
  width: 100vw;

  @media screen and (min-width: $breakpoint_l) {
    border-top: 2px solid $gainsboro;
    width: 100%;
    left: 0px;
  }
}

.tabsSection {
  overflow: hidden;
}

.hidden {
  display: none;
}

.showTabs {
  display: block;
  width: auto;
  overflow: none;
  white-space: nowrap;
  padding: 15px 0 5px;

  @media screen and (min-width: $breakpoint_l) {
    padding: 30px 0 10px;
  }
}

.allowOverflow {
  overflow: auto;
}
