$cdn: 'https://cdn.millions.co';
@import "styles2/scss/_base";

.root {
  color: $black;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.imageAndContentWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-top: 17px;

  @media screen and (min-width: $breakpoint_l) {
    margin: 17px 12px 0px;
  }
}

.imageWrapper {
  @media screen and (min-width: $breakpoint_l) {
    margin: 0;
  }

  .image {
    margin: 0 auto;
    border: 2px solid $harvest-gold;
    border-radius: 50%;
    width: 90px;
    height: 90px;
    overflow: hidden;

    @media screen and (min-width: $breakpoint_l) {
      border: 4px solid $harvest-gold;
      width: 250px;
      height: 250px;
    }
  }
}

.contentWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $breakpoint_l) {
    margin-left: 25px;
  }
}

.nameAndBio {
  flex-grow: 1;
}

.nameWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}

.athleteName {
  flex-grow: 1;
  margin-bottom: 4px;

  @include text-3xl;
  text-transform: capitalize;

  @media screen and (min-width: $breakpoint_l) {
    margin-bottom: 12px;

    @include text-6xl;
    text-transform: capitalize;
  }
}

.longerName {
  @include font_size(26px);

  @media screen and (min-width: $breakpoint_l) {
    @include font_size(52px);
  }
}

.longestName {
  @include font_size(20px);

  @media screen and (min-width: $breakpoint_l) {
    @include font_size(46px);
  }
}

.desktopFollowAndBook {
  display: flex;
  justify-content: flex-end;
  flex-grow: 0;
  max-width: 397px;
  width: 100%;
}

.description {
  color: $dim-grey;
  width: 100%;
  padding: 5px 0;

  @include text-xs-regular;
  font-weight: 500;

  @media screen and (min-width: $breakpoint_l) {
    padding: 10px 0;

    @include text-2xl;
    @include line-height(26);

    font-weight: 500;
    text-transform: none;
    min-height: 100px;
  }
}

.hashtags {
  @media screen and (min-width: $breakpoint_l) {
    padding-top: 10px;
    min-height: 60px;
  }
}

.topSection {
  padding-bottom: 0px;
}

.mobileFollowAndBook {
  width: 100%;
}

.tagsListColumn {
  flex-flow: row wrap;
}
