$cdn: 'https://cdn.millions.co';
@import "styles2/scss/base";

.root {
  background-color: $white;
  padding: 0px;
  border: none;

  & > div {
    padding: 0px;
  }
}

.title {
  position: relative;
  margin-bottom: 30px;
  margin-top: -20px;
  color: $black;
  text-align: start;

  @include text-2xl;

  @media screen and (min-width: $breakpoint_l) {
    @include text-4xl;
  }
}

.rootCarousel {
  [class*="nav"] {
    top: -66px;
    height: 60px;
  }
}
