$cdn: 'https://cdn.millions.co';
@import "styles2/scss/base";

.root {
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  outline: none;

  &.default {
    @media screen and (min-width: $breakpoint_l) {
      display: block;
    }
  }

  &.theme {
    &-white {
      background: $white;

      .storeButton {
        color: $dove-gray;
      }

      .viewMoreLabel {
        color: $black;
      }
    }

    &-black {
      background-color: $black;

      .storeButton {
        color: $white;

        &:hover {
          color: $white;
        }
      }

      .viewMoreLabel {
        color: $white;
      }
    }
  }
}

.imageWrapper {
  position: relative;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: flex-start;
  background-color: $white;
  border: $border-light-grey;
  overflow: hidden;
  aspect-ratio: 1;
}

.infoWrapper {
  margin: 6px 0 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  @include text-sm-medium;
  @include line_clamp(2);

  &.large {
    @media screen and (min-width: $breakpoint_l) {
      height: 42px;
      @include text-lg-medium;
    }
  }
}

.infoFooter {
  margin: 8px 0 0;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}

.storeButton {
  margin: 0 0 4px 0;
  padding: 0;
  border: none;
  background-color: transparent;
  text-align: left;
  cursor: pointer;
  color: $black;
  text-decoration: none; 

  @include inline_overflow_ellipsis;
  @include text-sm-regular-underline;

  @media screen and (min-width: $breakpoint_l) {
    margin-right: 7px;

    @include text-sm-regular-underline;
  }
}

.viewMoreLabel {
  width: 100%;
  border: $border-light-grey;
  text-align: center;
  padding: 8px 0;

  @include text-xs-medium-uppercase;

  @media screen and (min-width: $breakpoint_l) {
    margin-top: 8px;
  }
}
